import React, { useRef, useEffect } from "react";
import { Link } from "gatsby";

//Components
import Layout from "../../components/Layout";
import Category from "../../components/Category";

const data = [
  {
    title: "Quirófano",
    subsubCategories: [
      {
        title:
          "Monitores de signos vitales/Monitor BIS/Monitor CO2 transcutáneo TCPCO2/",
        subsubsubCategories: [],
      },
      {
        title: "Centrales de monitoreo",
        subsubsubCategories: [],
      },
      {
        title: "Maquina de anestesia",
        subsubsubCategories: [],
      },
      {
        title: "Ventiladores",
        subsubsubCategories: [],
      },
      {
        title: "Lámparas de quirófano LED/ Lámparas de emergencia LED",
        subsubsubCategories: [],
      },
      {
        title: "Mesa quirófano",
        subsubsubCategories: [],
      },
      {
        title: "Desfibrilador-Monitor/DEA/Marcapasos",
        subsubsubCategories: [],
      },
      {
        title: "Carro rojo de emergencia y/o de paro",
        subsubsubCategories: [],
      },
    ],
  },
  {
    title: "Accesorios",
    subsubCategories: [
      {
        title: "Reanimadores cardiopulmonares",
        subsubsubCategories: [],
      },
      {
        title: "Humidificadores",
        subsubsubCategories: [],
      },
      {
        title:
          "Kit de laringoscopios mcintosh (curvo) Miller (recto) LED/HALOGENO",
        subsubsubCategories: [],
      },
      {
        title: "Cables y latiguillos ECG",
        subsubsubCategories: [],
      },
      {
        title:
          "Sensor reusable SpO2 tipo clip (adulto, pediátrico, neonatal, multisitio)",
        subsubsubCategories: [],
      },
      {
        title: "Brazaletes PANI (diferentes tamaños)",
        subsubsubCategories: [],
      },
      {
        title: "Transductores de presión invasiva/interfaz",
        subsubsubCategories: [],
      },
    ],
  },
  {
    title: "Diagnostico",
    subsubCategories: [
      {
        title: "Electrocardiógrafos",
        subsubsubCategories: [],
      },
      {
        title: "Telemetría (holter,mapa, spot check, oxímetros de pulso)",
        subsubsubCategories: [],
      },
      {
        title: "Ultrasonido eco doppler",
        subsubsubCategories: [],
      },
      {
        title: "Tococardiógrafo",
        subsubsubCategories: [],
      },
      {
        title: "Fonodetectores",
        subsubsubCategories: [],
      },
      {
        title: "Negatoscopio LED",
        subsubsubCategories: [],
      },
      {
        title: "Unidad de rayos X y digitalizador",
        subsubsubCategories: [],
      },
      {
        title: "Lámparas de examinación LED/HALOGENO (chicote)",
        subsubsubCategories: [],
      },
      {
        title: "Endoscopio",
        subsubsubCategories: [],
      },
      {
        title: "Monitores grado medico full HD",
        subsubsubCategories: [],
      },
    ],
  },
  {
    title: "Infusión",
    subsubCategories: [
      {
        title: "Bombas de infusión/Bomba de inyección volumétrica",
        subsubsubCategories: [],
      },
      {
        title: "Portavenoclisis rodable",
        subsubsubCategories: [],
      },
      {
        title: "Inyector medio de contraste",
        subsubsubCategories: [],
      },
    ],
  },
  {
    title: "Mobiliario y traslado hospitalario",
    subsubCategories: [
      {
        title: "Cama hospitalaria",
        subsubsubCategories: [],
      },
      {
        title: "Cuna pediátrica",
        subsubsubCategories: [],
      },
      {
        title: "Camilla de traslado",
        subsubsubCategories: [],
      },
      {
        title: "Camilla de emergencia",
        subsubsubCategories: [],
      },
      {
        title: "Camilla de recuperación",
        subsubsubCategories: [],
      },
      {
        title: "Reposet diálisis, hemodiálisis",
        subsubsubCategories: [],
      },
      {
        title: "Buro, mesa puente, bacinete, cunero.",
        subsubsubCategories: [],
      },
    ],
  },
];

const EquipoMedico = () => {
  return (
    <Layout>
      <Category data={data} title="Equipo medico hospitalario" />
    </Layout>
  );
};

export default EquipoMedico;

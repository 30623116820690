import React from "react";
import {
  categoryContainer,
  titleHeader,
  goBackButton,
  productsMainContainer,
  subCategoryContainer,
  subCategoryL1Title,
  subCategoryL2Title,
  subCategoryL3Title,
} from "./category.module.css";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const TitleHeader = ({ children }) => {
  return <h2 className={titleHeader}>{children}</h2>;
};
const GoBack = ({ children }) => {
  return (
    <button className={goBackButton}>
      <Link
        to="/productos"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        Regresar
      </Link>
    </button>
  );
};

const SubCategory = ({ title, subsubCategories }) => {
  return (
    <div className={subCategoryContainer}>
      <h3 className={subCategoryL1Title}>{title}</h3>
      {subsubCategories.length ? (
        subsubCategories.map((el) => {
          return (
            <>
              <h4 className={subCategoryL2Title}>{el.title}</h4>
              {el.subsubsubCategories.length ? (
                el.subsubsubCategories.map((sub3) => (
                  <h5 className={subCategoryL3Title}>{sub3}</h5>
                ))
              ) : (
                <></>
              )}
            </>
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
};

const Categories = ({ title, data }) => {
  return (
    <div className={categoryContainer}>
      <TitleHeader>{title}</TitleHeader>
      <GoBack />
      <div className={productsMainContainer}>
        {data.length ? (
          data.map((el) => {
            return (
              <SubCategory
                title={el.title}
                subsubCategories={el.subsubCategories}
              />
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Categories;
